export const quoteEndpoint = "https://quotes.rest/qod";

//express, mysql, nodejs, sql, firebase
export const data = {
  heading: "Hi there, I'm Mehul 👋",
  sections: [
    {
      section: "Projects",
      route: "/",
      icon: "far fa-file-code",
      color: "#000000",
    },
    {
      section: "Skills",
      route: "/skills",
      icon: "fas fa-book",
      color: "#000000",
    },
    {
      section: "Education",
      route: "/education",
      icon: "fas fa-university",
      color: "#000000",
    },
    {
      section: "About",
      route: "/about",
      icon: "fas fa-user",
      color: "#000000",
    },
  ],
  projects: [
    {
      title: "RWalls",
      tech: "Android, Kotlin, Jetpack Compose",
      brief:
        "RWalls is an app that allows users to choose an image from Reddit and set it as their device wallpaper. The app utilizes the Android jetpack library to link the data layer to the ui layer which was built using Jetpack Compose. The app also uses other new technologies like Kotlin coroutines, Flows, Data Binding, Navigation Component, Hilt, and WorkManager",
      repo: "https://github.com/meh430/RWalls",
      description:
        "This app takes a query and uses Reddit's REST api to pull data in a JSON format. It then parses the JSON data returned by the REST api and scrapes for image urls. Finally, the app loads the image urls to be set as wallpapers. The app also uses Kotlin's coroutines to efficiently fetch results returned from Reddit and update the user interface. The app even uses other new technologies like Data Binding, Navigation Component, Hilt, and WorkManager.",
      features: [
        "Search for subreddits",
        "Save favorite images to be viewed later",
        "Download images",
        "Cycle through favorites periodically",
      ],
      images: [
        "https://github.com/meh430/RWalls/raw/master/screens/Screenshot_20230106_144605_RWalls.png",
        "https://github.com/meh430/RWalls/raw/master/screens/Screenshot_20230106_144724_RWalls.png",
        "https://github.com/meh430/RWalls/raw/master/screens/Screenshot_20230106_144752_RWalls.png",
        "https://github.com/meh430/RWalls/raw/master/screens/Screenshot_20230106_144830_RWalls.png",
        "https://github.com/meh430/RWalls/raw/master/screens/Screenshot_20230106_144844_RWalls.png",
        "https://github.com/meh430/RWalls/raw/master/screens/Screenshot_20230106_144927_RWalls.png",
        "https://github.com/meh430/RWalls/raw/master/screens/Screenshot_20230108_005735_RWalls.png",
      ],
    },
    {
      title: "Woons",
      tech: "Android, Kotlin, XML",
      brief:
        "Woons is an Android app that can be used to read webtoons. The app uses the latest architecture components and libraries: view binding, Room, coroutines, livedata, dependency injection with Dagger 2, Retrofit2, Navigation component",
      repo: "https://github.com/meh430/Woons",
      description:
        "This app takes a query and uses Reddit's REST api to pull data in a JSON format. It then parses the JSON data returned by the REST api and scrapes for image urls. Finally, the app loads the image urls to be set as wallpapers. The app also uses Kotlin's coroutines to efficiently fetch results returned from Reddit and update the user interface. The app even uses other new technologies like Data Binding, Navigation Component, Hilt, and WorkManager.",
      features: [
        "Add webtoons to your library",
        "Track read chapters",
        "View webtoon info",
        "Browse different categories of webtoons",
        "Search for webtoons",
      ],
      images: [
        "https://raw.githubusercontent.com/meh430/Woons/master/screens/library_dark.jpg",
        "https://raw.githubusercontent.com/meh430/Woons/master/screens/discover_dark.jpg",
        "https://raw.githubusercontent.com/meh430/Woons/master/screens/browse_dark.jpg",
        "https://raw.githubusercontent.com/meh430/Woons/master/screens/search_dark.jpg",
        "https://raw.githubusercontent.com/meh430/Woons/master/screens/info_dark.jpg",
        "https://raw.githubusercontent.com/meh430/Woons/master/screens/reader.jpg",
        "https://raw.githubusercontent.com/meh430/Woons/master/screens/library_light.jpg",
        "https://raw.githubusercontent.com/meh430/Woons/master/screens/discover_light.jpg",
        "https://raw.githubusercontent.com/meh430/Woons/master/screens/browse_light.jpg",
        "https://raw.githubusercontent.com/meh430/Woons/master/screens/search_light.jpg",
        "https://raw.githubusercontent.com/meh430/Woons/master/screens/info_light.jpg",
      ],
    },
    {
      title: "Woons API",
      tech: "Node.js, Express, TypeScript",
      brief:
        "Woons API scrapes a webtoon website to search for webtoons and read webtoons on different clients",
      repo: "https://github.com/meh430/webtoons-api",
      description:
        "This app takes a query and uses Reddit's REST api to pull data in a JSON format. It then parses the JSON data returned by the REST api and scrapes for image urls. Finally, the app loads the image urls to be set as wallpapers. The app also uses Kotlin's coroutines to efficiently fetch results returned from Reddit and update the user interface. The app even uses other new technologies like Data Binding, Navigation Component, Hilt, and WorkManager.",
      features: [
        "View webtoon info",
        "Browse different categories of webtoons",
        "Search for webtoons",
      ],
      images: [],
    },
    {
      title: "Personal Website",
      tech: "TypeScript, React, Material-UI",
      brief:
        "My personal website is built mainly using ReactJS and the SPA architecture. The site also makes use of various node modules like Material-UI to make sure that the site provides a cohesive user experience.",
      repo: "https://github.com/meh430/meh430.github.io",
      description:
        "This site uses the React DOM to render different components that represent parts of the site allowing for code reusability. The React router is also used which aids in implementing the SPA architecture and allows for faster page switches.",
      features: [
        "Displays time, date, and a greeting",
        "Displays an inspirational quote every day",
        "A search bar that redirects queries to Google",
        "Uses react router for fast page switchs",
      ],
      images: [
        "https://raw.githubusercontent.com/meh430/meh430.github.io/source/src/assets/personal-site.png",
      ],
    },
    {
      title: "Rank 10 App",
      tech: "Flutter, dart",
      repo: "https://github.com/meh430/rankten-flutter",
      brief:
        "Rank 10 is a platform that allows users to create and share top ten lists on any topic. This repository is a cross platform mobile client for Rank 10 built using the Flutter framework.",
      images: [
        "https://github.com/meh430/rankten-flutter/blob/master/screens/reorder.gif?raw=true",
        "https://github.com/meh430/rankten-flutter/blob/master/screens/discover_light.png?raw=true",
        "https://github.com/meh430/rankten-flutter/blob/master/screens/discover_dark.png?raw=true",
        "https://github.com/meh430/rankten-flutter/blob/master/screens/comments_light.png?raw=true",
        "https://github.com/meh430/rankten-flutter/blob/master/screens/comments_dark.png?raw=true",
      ],
      features: [
        "Create and customize an account",
        "Create top ten lists that are either private or public",
        "Drag and drop to reorder rankings within your list",
      ],
    },

    {
      title: "Rank 10 Site",
      tech: "React, Material-UI",
      repo: "https://github.com/meh430/rankten",
      brief:
        "Rank 10 is a platform that allows users to create and share top ten lists on any topic. This repository is a responsive web client for Rank 10 built using the React library and Material-UI.",
      images: [
        "https://github.com/meh430/rankten/blob/master/screens/edit.gif?raw=true",
        "https://github.com/meh430/rankten/blob/master/screens/reorder.gif?raw=true",
        "https://github.com/meh430/rankten/blob/master/screens/discover_light.png?raw=true",
        "https://github.com/meh430/rankten/blob/master/screens/discover_dark.png?raw=true",
        "https://github.com/meh430/rankten/blob/master/screens/comments_light.png?raw=true",
        "https://github.com/meh430/rankten/blob/master/screens/comments_dark.png?raw=true",
      ],
      features: [
        "Create and customize an account",
        "Create top ten lists that are either private or public",
        "Drag and drop to reorder rankings within your list",
      ],
    },

    {
      title: "Rank 10 API",
      tech: "Node.js, Express, MySQL, Redis, Docker",
      repo: "https://github.com/meh430/rankten-express",
      brief:
        "Rank 10 is a platform that allows users to create and share top ten lists on any topic. This project is a RESTful API for Rank 10 built using Node.js, Express, MySQL, and Redis.",
      images: [
        "https://github.com/meh430/rankten/blob/master/screens/ranktenlogo.png?raw=true",
      ],
      features: [
        "Uses transactions to maintain data integrity",
        "Uses Redis to speed up API call times",
        "Dockerized for easy deployment",
      ],
    },
    {
      title: "MehBot",
      tech: "Python, MongoDB, Docker",
      brief:
        "MehBot is a Discord bot written in Python that is mainly centered around getting information from the internet. It has been dockerized for easy deployment and uses MongoDB to persist data.",
      repo: "https://github.com/meh430/MehBot",
      description:
        "This is a bot made for the popular chat client Discord using its Python API wrapper, Discord.py. This project was mainly a learning experience that taught me how to use MongoDB, Docker, and improved my Python skills.",
      features: [
        "Download songs from YouTube in an mp3 format",
        "Play YouTube videos in a voice channel",
        "Find info on anime",
        "Have a list of favorite Anime unique to each user",
      ],
      images: [
        "https://raw.githubusercontent.com/meh430/meh430.github.io/0c14f3f5c4a2a3ec70aa7047a6c8df8239dc7da4/src/about/imgs/dbot0.png",
        "https://raw.githubusercontent.com/meh430/meh430.github.io/0c14f3f5c4a2a3ec70aa7047a6c8df8239dc7da4/src/about/imgs/dbot1.png",
        "https://raw.githubusercontent.com/meh430/meh430.github.io/0c14f3f5c4a2a3ec70aa7047a6c8df8239dc7da4/src/about/imgs/dbot2.png",
        "https://raw.githubusercontent.com/meh430/meh430.github.io/0c14f3f5c4a2a3ec70aa7047a6c8df8239dc7da4/src/about/imgs/dbot3.png",
        "https://raw.githubusercontent.com/meh430/meh430.github.io/0c14f3f5c4a2a3ec70aa7047a6c8df8239dc7da4/src/about/imgs/dbot4.png",
      ],
    },

    {
      title: "St. Augustine Android App",
      tech: "Android, Java, XML, Firebase",
      brief:
        "The official Android app for the St. Augustine Catholic High School made for students, by students. The app is available to students of the mentioned school and uses Google's Firebase as its backend.",
      repo: "https://github.com/meh430/StAugustineCHSAndroidApp",
      description:
        "I was an app developer at St. Augustine's app development club. During my time as a member, I worked in a team and implemented a system-wide dark mode, a ui revamp, an faq screen that pulled data from Firebase, a sidebar with relevant links, and a notes feature.",
      features: [
        "Displays cafeteria menu for the day",
        "View daily announcements",
      ],
      images: [
        "https://github.com/meh430/meh430.github.io/blob/0c14f3f5c4a2a3ec70aa7047a6c8df8239dc7da4/src/about/imgs/1586550531665.png?raw=true",
        "https://github.com/meh430/meh430.github.io/blob/0c14f3f5c4a2a3ec70aa7047a6c8df8239dc7da4/src/about/imgs/1586550536263.png?raw=true",
      ],
    },
    {
      title: "Share Easy",
      tech: "React, Node.js, Firebase",
      brief:
        "Share Easy is a React app that allows users to upload files and generate download links that expire in 12 hours to quickly and easily share fairly large files on platforms that have size limits.",
      repo: "https://github.com/meh430/ShareEasy",
      description:
        "This web app uses react to handle the user interface and uses Firebase to handle the storage of uploaded files. Uploaded files are only accessible for around 12 hours so the generated download links are temporary. The browser's local storage is used to keep track of any other generated links that have not expired.",
      features: [
        "Quickly upload files",
        "Generates download links that can be easily shared",
      ],
      images: [
        "https://camo.githubusercontent.com/f14a669a6b45a603c48bf6b606f04fb046860c78/68747470733a2f2f63646e2e646973636f72646170702e636f6d2f6174746163686d656e74732f3731373438373739313439313132313136322f3732343037353133353931363537323730322f756e6b6e6f776e2e706e67",
      ],
    },
    {
      title: "Censor Me!",
      tech: "JavaScript, HTML/CSS, Chrome",
      brief:
        "Censor Me is a Chrome extension that blocks out specified keywords on any website. The extension includes a popup user interface to allow for the entry of any keywords to  be blocked.",
      repo: "https://github.com/meh430/CensorMe",
      description:
        "The extension uses Chrome's sync storage API to store user-entered keywords. Thanks to the API, the data is saved to the user's Google account and will be visible on other devices as well. This extension also allows the user to quickly toggle off the blocking globally and on a per-site basis.",
      features: [
        "Blocks specified keywords on any website",
        "Specified keywords are saved across devices",
        "Disable the keyword blocking on a specific site",
      ],
      images: [
        "https://camo.githubusercontent.com/ba51d05cef03963b28a439b7f35c0e3b495942ff/68747470733a2f2f63646e2e646973636f72646170702e636f6d2f6174746163686d656e74732f3731373438373739313439313132313136322f3734313635383930353230393230383934322f756e6b6e6f776e2e706e67",
        "https://camo.githubusercontent.com/b4e5c5152344ed3b1e696b0cfe5b6d3db0e1e817/68747470733a2f2f63646e2e646973636f72646170702e636f6d2f6174746163686d656e74732f3731373438373739313439313132313136322f3734313635383736323633363638393433382f756e6b6e6f776e2e706e67",
      ],
    },
    {
      title: "Bulk Image Downloader",
      tech: "JavaScript, HTML/CSS, Chrome",
      brief:
        "Bulk Image Downloader is a simple Chrome extension that uses a script to download all images opened in a new tab and allows for adding new images through Chrome's context menu.",
      repo: "https://github.com/meh430/bulk-image-dl",
      description: "",
      features: [
        "Select all the images present on the page",
        "Set minimum height and width filters",
        "Remove selected image by clicking on it in the popup",
        "Download images open in new tab",
      ],
      images: [
        "https://camo.githubusercontent.com/2ea317a770cfdfadf0564b17027064163568e80d/68747470733a2f2f6d656469612e646973636f72646170702e6e65742f6174746163686d656e74732f3731373438373739313439313132313136322f3734313635393639373139303533353235392f756e6b6e6f776e2e706e673f77696474683d31313530266865696768743d363139",
      ],
    },
    {
      title: "Math Me!",
      tech: "Android, Java, XML",
      brief:
        "Math Me is an Android app meant to improve mental math and basic arithmetic. Users can practice their addition, subtraction, multiplication, and division skills using this app and also track their progress.",
      repo: "https://github.com/meh430/MathMe",
      description:
        "If you wanted to improve the speed of your mental math or just decrease your reliance on calculators, this app is for you! There are various modes that test different aspects of arithmetic. This app uses the Android Room library and other Android architectural components like LiveData to effectively store past scores.",
      features: [
        "Different modes to help practice mental arithmetic skills",
        "Notification reminder to practice math",
        "Different settings to change difficulty",
      ],
      images: [
        "https://github.com/meh430/MathMe/blob/master/screens/main_dark.png?raw=true",
        "https://github.com/meh430/MathMe/blob/master/screens/main_light.png?raw=true",
        "https://github.com/meh430/MathMe/blob/master/screens/settings_dark.png?raw=true",
        "https://github.com/meh430/MathMe/blob/master/screens/time_dark.png?raw=true",
        "https://github.com/meh430/MathMe/blob/master/screens/time_light.png?raw=true",
        "https://github.com/meh430/MathMe/blob/master/screens/score_dark.png?raw=true",
      ],
    },
    {
      title: "Chess",
      tech: "Java, swing/awt, sockets",
      brief:
        "A multiplayer chess game made using Java swing/awt and the MVC software design pattern. One instance of the game is run as a server while the other is run as a client who then joins the server.",
      repo: "https://github.com/meh430/Java-Chess",
      description:
        "This was my final project for the ISC4U1 computer science course in high school. It makes use of the MVC software design pattern to separate the logic associated with chess and the UI.",
      features: [
        "Text chat",
        "A tutorial that teaches the basics of chess",
        "Different board colors for customization",
        "Stores a log of the game which can be replayed",
      ],
      images: [
        "https://github.com/meh430/meh430.github.io/blob/0c14f3f5c4a2a3ec70aa7047a6c8df8239dc7da4/src/about/imgs/chess1.png?raw=true",
        "https://github.com/meh430/meh430.github.io/blob/0c14f3f5c4a2a3ec70aa7047a6c8df8239dc7da4/src/about/imgs/chess2.png?raw=true",
      ],
    },
  ],
  skills: [
    {
      skill: "Java",
      icon: "fab fa-java",
      color: "#FF0000",
    },
    {
      skill: "JavaScript",
      icon: "fab fa-js-square",
      color: "#DBDB38",
    },
    {
      skill: "Kotlin",
      icon: "https://img.icons8.com/color/48/000000/kotlin.png",
    },
    {
      skill: "C++",
      icon: "https://img.icons8.com/color/48/000000/c-plus-plus-logo.png",
    },
    {
      skill: "Python",
      icon: "fab fa-python",
      color: "#FFB70B",
    },
    {
      skill: "Dart",
      icon: "https://img.icons8.com/color/48/000000/devpost.png",
    },
    {
      skill: "Go",
      icon: "https://img.icons8.com/color/48/000000/golang.png",
    },
    {
      skill: "Android",
      icon: "fab fa-android",
      color: "#3DDC84",
    },
    {
      skill: "ReactJS",
      icon: "fab fa-react",
      color: "#0BBFFF",
    },
    {
      skill: "Flutter",
      icon: "https://img.icons8.com/color/48/000000/flutter.png",
    },
    {
      skill: "MongoDB",
      icon: "fas fa-database",
      color: "#32910A",
    },
    {
      skill: "Flask",
      icon: "https://img.icons8.com/doodle/48/000000/test-tube--v1.png",
    },
    {
      skill: "Redis",
      icon: "https://img.icons8.com/color/48/000000/redis.png",
    },
    {
      skill: "Docker",
      icon: "fab fa-docker",
      color: "#437CFF",
    },
    {
      skill: "Node.js",
      icon: "fab fa-node-js",
      color: "green",
    },
    {
      skill: "MySQL",
      icon: "fas fa-database",
      color: "#f29111",
    },
    {
      skill: "ExpressJS",
      icon: "https://jsurt.github.io/jacks-portfolio/images/color-express-icon%20(1).png",
    },
    {
      skill: "TypeScript",
      icon: "https://miro.medium.com/max/816/1*TpbxEQy4ckB-g31PwUQPlg.png",
    },
    {
      skill: "Firebase",
      icon: "https://cdn4.iconfinder.com/data/icons/google-i-o-2016/512/google_firebase-2-512.png",
    },
  ],
  education: [
    {
      details: "Activities: Track & Field, Chess club",
      title: "Westbrough High School",
      period: "Sept 2016 - Feb 2018",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e5/Wisconsin_Badgers_logo.svg/200px-Wisconsin_Badgers_logo.svg.png",

      brief:
        "Westborough High School arguably improved my time management skills the most. The transition from elementary to secondary school is drastic because of the new environment and there is definitely more work assigned in high school compared to elementary school. On top of the change in environment and the increased workload, I joined the track and field club that held practice that ran for 2 hours every week day which forced me to carefully budget my time.",
    },
    {
      title: "St. Augustine Catholic High School",
      period: "Mar 2018 - Jun 2020",
      image:
        "https://raw.githubusercontent.com/meh430/meh430.github.io/0c14f3f5c4a2a3ec70aa7047a6c8df8239dc7da4/src/about/imgs/stalogo.png",
      details: "Activities: App Dev club, Habitat for Humanity volunteer",
      brief:
        "During my time here, I was a member of the math club and the app development club. In the math club, students got together to solve problems from past math contests to improving our problem solving abilities. In the app club, I learned a lot about developing with a team and how to adjust my workflow accordingly. I also volunteered as a math tutor and at the Habitat for Humanity. While participating in all of these activities, I maintained a 98% overall average in my grade 12 year.",
    },
    {
      details: "Bachelor of Computer Science, GPA: 3.97",
      title: "University Of Waterloo",
      period: "Sept 2020 - Apr 2025",
      image:
        "https://raw.githubusercontent.com/meh430/meh430.github.io/0c14f3f5c4a2a3ec70aa7047a6c8df8239dc7da4/src/about/imgs/uwlogo.png",
      brief:
        "Currently, I’m lucky enough to count myself as a student at the University of Waterloo where I am majoring in computer science. I chose Waterloo specifically because I think experiential learning is extremely important for success. Waterloo’s co-op program offers more opportunities than most other universities and I plan on taking full advantage of these opportunities. Waterloo also has some of the best computer science professors in the world and it is an honour to learn from them.",
    },
  ],
  interests: [
    {
      title: "Programming",
      image:
        "https://raw.githubusercontent.com/meh430/meh430.github.io/0c14f3f5c4a2a3ec70aa7047a6c8df8239dc7da4/src/about/imgs/prog.png",
      brief:
        "Programming gives me a great sense of accomplishment. I occasionally peruse Project Euler to find programming problems to solve. After minutes of planning, mapping, and coding, entering the answer into the website and getting a big green checkmark feels truly amazing. Personal projects that demonstrate my growth as a programmer also serve as a source of pride. one of my first projects with Android was to make an easy to use arithmetic practice app and it was exciting to see the app come alive. It was also interesting to learn how an OS I used every day worked internally.",
    },
    {
      title: "Android Tweaks",
      image:
        "https://www.gizmochina.com/wp-content/uploads/2020/04/LineageOS-Dark-Logo-1024x576.jpg",
      brief:
        "My first phone was a very mediocre, low-spec Android phone. To make the experience worse, it ran a heavily skinned version of Android that made everything slower and did not get any more software updates. That’s when I dived down the rabbit hole of Android custom roms and tweaks. Since then, I’ve flashed countless different roms on my phone and made all sorts of tweaks like overclocking the cpu and the display's refresh rate. If you give me an old phone, I would probably be entertained for the whole day trying to breathe new life into it by flashing different roms. Currently, I’m running AICP on my phone which is a rom based on the popular LineageOS.",
    },
    {
      title: "Gaming",
      image:
        "https://store-images.s-microsoft.com/image/apps.58752.68182501197884443.ac728a87-7bc1-4a0d-8bc6-0712072da93c.0cf58754-9802-46f8-8557-8d3ff32a627a?mode=scale&q=90&h=720&w=1280",
      brief:
        "I really enjoy playing an immersive video game. My favorites include The Witcher 3, Red Dead Redemption 2, and Resident Evil 2.",
    },
    {
      title: "Anime",
      image:
        "https://finalweapon.net/wp-content/uploads/2019/07/attack-on-titan.jpg",
      brief:
        "I enjoy watching anime from time to time. My favorites include the Attack on Titan series, Fate/Zero, Steins;Gate, and JoJo. ",
    },
    {
      title: "Shows",
      image:
        "https://cdn.vox-cdn.com/thumbor/hOagCnRe2cCIIZhcLuJUH5ZPVvk=/0x0:1075x604/1200x800/filters:focal(452x216:624x388)/cdn.vox-cdn.com/uploads/chorus_image/image/66255911/Screen_Shot_2020_02_05_at_9.44.59_AM.0.png",
      brief:
        "I usually watch comedy shows and I enjoy all the popular sitcoms like Friends, The Office, and Brooklyn 99, but my favorite show has to be Bojack Horseman.",
    },
    {
      title: "Books",
      image:
        "https://www.grandforksherald.com/incoming/article1118336.ece/alternates/BASE_LANDSCAPE/605074%2BPrincessBook.jpg",
      brief:
        "I used to be a very avid reader until High school sort of killed my love for books. I still do enjoy reading but I don’t have as much time to sink into them as I used to. My favorite book is the Princess Bride.",
    },
  ],
  profile: {
    image:
      "https://raw.githubusercontent.com/meh430/meh430.github.io/0c14f3f5c4a2a3ec70aa7047a6c8df8239dc7da4/src/about/imgs/prof.png",
    bio:
      "Hello, my name is Mehul Pillai. I’m an aspiring software engineer who loves to" +
      " solve problems and build applications. I am fascinated by the idea that behind every" +
      " interaction we have with technology, lies lines of code written by someone, and I have" +
      " strong desire to write code for society's betterment. One of the main reasons I actively" +
      " pursue computer science is its potential to incite change. The opportunity to change" +
      " society for the better through programming is immense and I hope to spearhead such a change in the future.",
    contactsLink: [
      {
        url: "https://www.linkedin.com/in/mehul-pillai-36b343170/",
        isExternal: false,
        iconColor: "#0072b1",
        image: "fab fa-linkedin",
      },
      {
        url: "https://github.com/meh430",
        isExternal: false,
        iconColor: "black",
        image: "fab fa-github-square",
      },
      {
        url: "mailto: mehulpillai30@gmail",
        isExternal: false,
        iconColor: "#D44638",
        image: "fas fa-envelope",
      },
    ],
  },
  backgrounds: [
    "https://stormandsky.com/gif/14.gif",
    "https://i.redd.it/5oxeqrahdap41.jpg",
    "https://i.redd.it/p8dxzsrk1ap41.jpg",
    "https://i.redd.it/g5g7mtzvmht01.jpg",
    "https://external-preview.redd.it/n8tzjgP6wltAEgv5Cpdk2dEnlODfm09FQaKx4oINK1k.jpg?auto=webp&s=611bb4b9e3297e11390d2452ebebbdccf3224a4b",
    "https://i.imgur.com/CS8QhJG.jpg",
    "https://i.imgur.com/H5k6AOa.jpg",
    "https://i.imgur.com/Kt0jUHf.jpg",
    "https://i.redd.it/godb3yiihvaz.jpg",
    "https://i.redd.it/nt7hai6h6ccz.jpg",
    "https://512pixels.net/downloads/macos-wallpapers/10-9.jpg",
    "https://512pixels.net/downloads/macos-wallpapers/10-0_10.1.png",
    "https://512pixels.net/downloads/macos-wallpapers/10-5.png",
    "https://512pixels.net/downloads/macos-wallpapers/10-7.png",
    "https://512pixels.net/downloads/macos-wallpapers/10-10.jpg",
    "https://512pixels.net/downloads/macos-wallpapers/10-11.jpg",
    "https://512pixels.net/downloads/macos-wallpapers/10-15-Day.jpg",
    "https://512pixels.net/downloads//macos-wallpapers/10-14-Night.jpg",
    "https://512pixels.net/downloads/macos-wallpapers/10-14-Day.jpg",
    "https://512pixels.net/downloads/macos-wallpapers/10-13.jpg",
  ],
};
